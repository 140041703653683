import React from "react";

import VisaIcon from "../images/service-icons/visa-processing.svg";
import VisaRequirementsIcon from "../images/service-icons/visa-requirements.svg";
import InterviewIcon from "../images/service-icons/visa-interview.svg";
import Eyebrow from "./eyebrow";
import ServiceItem from "./serviceItem";

const Services = () => {
  return (
    <div id="#services">
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-8">
              <Eyebrow label="SERVICES" />
              <h2 className="font-display md:text-display-xl text-display-md pt-5">
                Hassle free visa approval with our <span className="italic">visa application services</span>{" "}
              </h2>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-8">
            <ServiceItem
              icon={VisaIcon}
              title="Visa Processing Assistance"
              description="We provide superior consulting services for your visa 
              processing needs. We are experienced in obtaining and processing 
              visas from countries including but not limited to the United States, 
              Canada, member states of the European Union (Schengen), Australia, 
              South Korea, and Japan."
            />
            <ServiceItem
              icon={VisaRequirementsIcon}
              title="Filing Documentary Requirements"
              description="We will guide you through the whole visa application process, 
              including filling out forms, obtaining documentary requirements, as well 
              as submitting these requirements online. No tickets and hotel bookings? 
              No problem - we'll get that information for you too."
            />
            <ServiceItem
              icon={InterviewIcon}
              title="US Visa Interview Coaching"
              description="We will coach you for your US Visa Interview, where
              we will brief you with possible questions specific to the details you've
              provided in your application forms, as well as provide guidance on how to
              approach and respond to questions asked by US consuls with confidence."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
